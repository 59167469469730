var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{attrs:{"flat":"","accordion":"","multiple":""},model:{value:(_vm.expansion),callback:function ($$v) {_vm.expansion=$$v},expression:"expansion"}},[_vm._l((_vm.proxyValue),function(version){return _c('v-expansion-panel',{staticClass:"additionalPanel",attrs:{"readonly":""}},[_c('v-expansion-panel-header',{staticClass:"px-3 py-0",staticStyle:{"min-height":"56px"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(version.loaded === false)?_c('v-progress-circular',{attrs:{"color":"primary","size":22,"indeterminate":""}}):_vm._e()]},proxy:true}],null,true)},[_c('v-row',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_c('span',{staticClass:"primary--text mr-3"},[_vm._v("v "+_vm._s(version.version))]),_c('span',{staticClass:"text--text"},[_vm._v(_vm._s(version.name)+" ")]),_c('v-icon',{staticClass:"ml-3 mr-2",attrs:{"color":"textGray","size":"22"}},[_vm._v("mdi-calendar-clock-outline")]),_c('span',{staticClass:"textGray--text"},[_vm._v(_vm._s(_vm.convertDate(version.date_from))+" - "+_vm._s(_vm.convertDate(version.date_to)))])],1),_c('span',[_c('v-btn',{staticClass:"px-3 mx-1 text-none",attrs:{"elevation":"0","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('openParser', {
                territory: _vm.territory,
                contract: _vm.pact,
                version: version.version,
                contract_id: version.id,
                contract_type: 1,
              })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-file-document-outline")]),_vm._v("Загрузить тарифы")],1),_c('v-btn',{staticClass:"mr-3 px-0",attrs:{"elevation":"0","min-width":"40px","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.download(version.file)}}},[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v("mdi-file-download-outline")])],1)],1)])],1),_c('v-expansion-panel-content',{staticClass:"px-3"},[_c('v-divider',{staticClass:"mb-3"}),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"#EDF5FD","elevation":"0","block":""},on:{"click":function($event){return _vm.openDialog(version)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4E9EEE"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"font-size-14 font-weight-400 text-none",staticStyle:{"text-transform":"none","color":"#4e9eee"}},[_vm._v("Дополнительное соглашение")])],1),_vm._l((version.items),function(subversion){return _c('v-row',{key:subversion.id,staticClass:"d-flex mx-3 align-center justify-space-between"},[_c('span',[_c('span',{staticClass:"primary--text mr-3"},[_vm._v("v "+_vm._s(subversion.version))]),_c('span',{staticClass:"text--text"},[_vm._v(_vm._s(subversion.name)+" ")]),_c('v-icon',{staticClass:"ml-3 mr-2",attrs:{"color":"textGray","size":"22"}},[_vm._v("mdi-calendar-clock-outline")]),_c('span',{staticClass:"textGray--text"},[_vm._v(_vm._s(_vm.convertDate(subversion.date_from))+" - "+_vm._s(_vm.convertDate(subversion.date_to)))]),(subversion.with_prolongation)?_c('span',{staticClass:"ml-3 px-1 py-1 font-size-14",staticStyle:{"background-color":"#eaf8ef","color":"#29b560"}},[_vm._v("Пролонгация")]):_vm._e()],1),_c('span',[(!subversion.with_prolongation)?_c('v-btn',{staticClass:"px-3 mx-1 text-none",attrs:{"elevation":"0","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('openParser', {
                territory: _vm.territory,
                contract: _vm.pact,
                version: subversion.version,
                contract_id: subversion.id,
                contract_type: 2,
              })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-file-document-outline")]),_vm._v("Загрузить тарифы")],1):_vm._e(),_c('v-btn',{staticClass:"px-0",attrs:{"elevation":"0","min-width":"40px","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.download(subversion.file)}}},[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v("mdi-file-download-outline")])],1)],1)])})],2)],1)}),_c('v-dialog',{attrs:{"width":"470"},model:{value:(_vm.dialog.isShow),callback:function ($$v) {_vm.$set(_vm.dialog, "isShow", $$v)},expression:"dialog.isShow"}},[(_vm.dialog.isShow)?_c('Dialog',{attrs:{"version":_vm.dialog.version,"pact":_vm.pact,"territory":_vm.territory},on:{"close":function($event){_vm.dialog.isShow = false},"refreshItem":function($event){return _vm.refreshItem(_vm.dialog.version)},"openParser":(e) => _vm.$emit('openParser', e)}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }