import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import changeMenu from '../changeMenu'
import useRequest from '@/compositions/useRequest'

const history = defineComponent({
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const loading = ref(false)
    const menuModel = ref(false)
    const dataTemplate = {
      1: null,
      2: null,
    }
    const data = ref(_.cloneDeep(dataTemplate))

    const convertDate = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    watch(
      () => menuModel.value,
      () => {
        if (menuModel.value) {
          getHistory()
        } else {
          data.value = _.cloneDeep(dataTemplate)
        }
      }
    )

    const getHistory = async () => {
      const requestData = []
      loading.value = true
      for (let i = 1; i <= 2; i++) {
        if (props.entity.single && props.entity.single !== i) continue
        const filter = [
          {
            alias: 'entity',
            value: [props.entity.type],
          },
          {
            alias: 'type_id',
            value: [i],
          },
        ]
        if (props.entity.type !== 'main') {
          filter.push({
            alias: 'entity_id',
            value: [props.entity.id],
          })
        }
        requestData.push(
          store.dispatch('list/get', [
            {
              alias: 'coefficient_history',
              filter,
            },
          ])
        )
      }
      await Promise.all(requestData).then((response) => {
        if (props.entity.single) {
          data.value[props.entity.single] = response[0].data.coefficient_history
        } else {
          response.forEach((item, index) => {
            data.value[index + 1] = item.data.coefficient_history
          })
        }
      })
      loading.value = false
    }

    return {
      convertDate,
      data,
      loading,
      menuModel,
    }
  },
})
export default history
