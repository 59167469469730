import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import Datepicker from '@/components/Date/Default/index.vue'

import useRequest from '@/compositions/useRequest'

const changeMenu = defineComponent({
  props: {
    type: {
      type: String,
      default: '',
    },
    //coef
    //rate
    //ind
  },
  components: {
    Datepicker,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const model = ref(false)
    const fields = {
      coef: null,
      date_from: null,
      date_to: null,
    }
    const formData = ref(_.cloneDeep(fields))

    const mask = (val) => {
      if (props.type === 'coef') {
        val = val?.toString()
        if (val && (val.length <= 1 || val[0] === '1')) return 'M'
        else return 'M.##'
      } else return undefined
    }

    const getUnix = (val) => {
      return moment(val, 'YYYY-MM-DD').unix()
    }

    watch(
      () => model.value,
      () => {
        if (!model.value) {
          formData.value = _.cloneDeep(fields)
        }
      }
    )

    return {
      model,
      formData,
      mask,
      getUnix,
    }
  },
})
export default changeMenu
