<template>
  <div class="d-flex flex-column flex-grow-1 h-100">
    <TableDefault @changeheadershow="changeheadershow" :options="config" />
  </div>
</template>

<script>
import { doljnost } from '@/pages'
import tableServiceX5 from '@/pages/doljnost/config/table-service-x5'
import useView from '@/compositions/useView.js'
import _ from 'lodash'

export default {
  name: 'Doljnost-View',

  components: {},
  methods: {
    changeheadershow(options) {
      const { headerEl, value } = options
      headerEl.isShow = value
    },
  },
  setup() {
    const config = _.cloneDeep(doljnost)
    const {
      initTableConfig,
      createHeadItem,
      convertConfigPanel,
      addCloseButton,
      configRouteConvert,
      convertFormConfig,
    } = useView({})
    configRouteConvert({
      config: config,
      newPath: 'edit',
      settings: {
        index: [1],
      },
    })
    console.log(config)
    configRouteConvert({
      config: tableServiceX5.config,
      newPath: 'editService',
      settings: {
        index: [1],
      },
    })
    config.detail.tabs.push(tableServiceX5)
    console.log(config)
    return {
      config,
    }
  },
}
</script>
